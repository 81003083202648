
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Steps",
  data() {
    return {
      steps: [
        { text: (this as any).$t("landingPage.in5SimpleStepsStep1") },
        { text: (this as any).$t("landingPage.in5SimpleStepsStep2") },
        { text: (this as any).$t("landingPage.in5SimpleStepsStep3") },
        { text: (this as any).$t("landingPage.in5SimpleStepsStep4") },
        { text: (this as any).$t("landingPage.in5SimpleStepsStep5") }
      ]
    };
  },
  computed: {
    ...mapGetters({
      setting: "helper/getSetting",
      homepageTextPrimary: "helper/getHomePageTextPrimaryStyle"
    })
  },
  mounted() {
    if (this.setting?.homepage_color) {
      document.documentElement.style.setProperty(
        "--homepage-step-number-bg",
        this.setting.homepage_color
      );
    }
  },
  beforeDestroy() {
    document.documentElement.style.setProperty(
      "--homepage-step-number-bg",
      "var(--blue"
    );
  }
});
